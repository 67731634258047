



























import Vue from 'vue';
import { IPostFields } from '../../@types/generated/contentful';
import { Block, BLOCKS, NodeData } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Fields } from 'src/types/interface';

export default Vue.extend({
  name: 'Post',
  props: ['postId'],
  data() {
    return {
      richText: '',
      data: {} as IPostFields,
    };
  },
  created() {
    this.getPost();
  },
  methods: {
    async getPost() {
      if (!this.postId) {
        console.warn('Post ID is missing');
        return;
      }

      try {
        const postEntry = await this.$contentful.getEntry<IPostFields>(
          this.postId,
        );
        if (!postEntry?.fields) {
          console.warn('Post data is missing fields');
          return;
        }

        const options = {
          renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
              const { file, description } = node.data.target.fields;
              return `<img src="${
                file.url
              }" width="100%" height="100%" alt="${description || 'Image'}"/>`;
            },
          },
        };

        this.richText = documentToHtmlString(postEntry.fields.content, options);
        this.data = postEntry.fields;
      } catch (error) {
        console.error('Error fetching post data:', error);
      }
    },
  },
});
